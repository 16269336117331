import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@/libs/axios";
import {isEmpty} from "@core/utils/filter";

export default function gridhousedataUseList(props) {
  // Use toast
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'day', label: '日期', sortable: true },
    { key: 'stores', label: '下单门店数', sortable: true},
    { key: 'products_to', label: '应履约商品数', sortable: true },
    { key: 'products_actual', label: '实际履约商品数', sortable: true },
    { key: 'receives', label: '直领', sortable: true },
    { key: 'freezes', label: '冻品', sortable: true },
    { key: 'others', label: '其他', sortable: true },
    { key: 'products_not', label: '未履约商品数', sortable: true },
    { key: 'agreement_product', label: '商品履约率', sortable: true },
    { key: 'agreement7', label: '干线7H后履约率', sortable: true },
    { key: 'agreement11', label: '11点履约率', sortable: true },
    { key: 'agreement16', label: '16点履约率', sortable: true },
    { key: 'sorted', label: '已分拣数', sortable: true },
    { key: 'sorted_due', label: '预期分拣件数', sortable: true },
    { key: 'sort_percent', label: '分拣率', sortable: true },
    { key: 'send_percent', label: '配送效率(件/H)', sortable: true },
    { key: 'actions', label: '保存' },
  ]

  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('day')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    axios.post('api/gridhousedata/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        gh_id: props.gh_id,
        year: props.year,
        month: props.month,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        for(let i=0;i<list.length;i++){
          list[i].agreement7 = isEmpty(list[i].agreement7) ?  '0%' : list[i].agreement7 + '%'
          list[i].agreement11 = isEmpty(list[i].agreement11) ? '0%' : list[i].agreement11 + '%'
          list[i].agreement16 = isEmpty(list[i].agreement16) ?  '0%' : list[i].agreement16 + '%'
          if(isEmpty(list[i].products_not)) list[i].products_not = 0
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

    // Extra Filters
  }
}
