<template>

  <div>
    <b-card>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!--日期-->
        <template #cell(day)="data">
          {{ data.item.month + '月' + data.item.day + '日' }}
        </template>

        <!--下单门店数-->
        <template #cell(stores)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              v-model="data.item.stores"
          />
        </template>

        <!--应履约商品件数-->
        <template #cell(products_to)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              v-model="data.item.products_to"
          />
        </template>

        <!--实际履约商品数-->
        <template #cell(products_actual)="data">
          {{ data.item.products_to - data.item.products_not}}
        </template>

        <!--直领-->
        <template #cell(receives)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              @blur="calculate1(data.item)"
              v-model="data.item.receives"
          />
        </template>

        <!--冻品-->
        <template #cell(freezes)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              @blur="calculate2(data.item)"
              v-model="data.item.freezes"
          />
        </template>

        <!--其他-->
        <template #cell(others)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              @blur="calculate3(data.item)"
              v-model="data.item.others"
          />
        </template>

        <!--未履约商品件数-->
        <template #cell(products_not)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              v-model="data.item.products_not"
          />
        </template>

        <!--商品履约率 1-未履约商品件数/应履约商品数-->
        <template #cell(agreement_product)="data">
          {{ data.item.products_to==0 ? '0%' : ((1 - data.item.products_not / data.item.products_to) * 100).toFixed(2) + '%' }}
        </template>

        <!--干线7H后履约率-->
        <template #cell(agreement7)="data">
          <b-form-input
              size="sm"
              style="width: 60px"
              v-model="data.item.agreement7"
          />
        </template>

        <!--11点履约率-->
        <template #cell(agreement11)="data">
          <b-form-input
              size="sm"
              style="width: 60px"
              v-model="data.item.agreement11"
          />
        </template>

        <!--16点履约率-->
        <template #cell(agreement16)="data">
          <b-form-input
              size="sm"
              style="width: 60px"
              v-model="data.item.agreement16"
          />
        </template>

        <!--已分拣数-->
        <template #cell(sorted)="data">
          <b-form-input
              size="sm"
              type="number"
              style="width: 50px"
              v-model="data.item.sorted"
          />
        </template>

        <!--预期分拣件数-->
        <template #cell(sorted_due)="data">
          {{ data.item.products_to }}
        </template>

        <!--分拣率 已分拣数/预期分拣件数-->
        <template #cell(sort_percent)="data">
          {{ isEmpty(data.item.sorted) || data.item.products_to==0 ? '0%' : ((data.item.sorted / data.item.products_to) * 100).toFixed(2) + '%' }}
        </template>

        <!--配送效率-->
        <template #cell(send_percent)="data">
          <b-form-input
              size="sm"
              style="width: 60px"
              v-model="data.item.send_percent"
          />
        </template>

        <!--保存-->
        <template #cell(actions)="data">
          <feather-icon @click="saveData(data.item)" icon="FileTextIcon"/>
        </template>

      </b-table>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty, second, addNumber, isEmptyNew
} from '@core/utils/filter'
import gridhousedataUseList from './gridhousedataUseItems'
import gridhousedataStore from './gridhousedataStore'
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    gh_id: {
      type: Number,
      default: 0
    },
    year: {
      type: Number,
      default: 0
    },
    month: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('gridhousedata')) store.registerModule('gridhousedata', gridhousedataStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('gridhousedata')) store.unregisterModule('gridhousedata')
    })


    const saveData = function (data) {
      let flag = true
      let exp = /^([1-9][0-9]*|0)$/
      if (!isEmpty(data.agreement7)) {
        if (data.agreement7.indexOf('%') !== -1) data.agreement7 = data.agreement7.substr(0, data.agreement7.indexOf('%'))
        if (isNaN(data.agreement7)) {
          toast.error('干线7H后履约率 请输入数字')
          flag = false
        }
      }
      if (!isEmpty(data.agreement11)) {
        if (data.agreement11.indexOf('%') !== -1) data.agreement11 = data.agreement11.substr(0, data.agreement11.indexOf('%'))
        if (isNaN(data.agreement11)) {
          toast.error('11点履约率 请输入数字')
          flag = false
        }
      }
      if (!isEmpty(data.agreement16)) {
        if (data.agreement16.indexOf('%') !== -1) data.agreement16 = data.agreement16.substr(0, data.agreement16.indexOf('%'))
        if (isNaN(data.agreement16)) {
          toast.error('16点履约率 请输入数字')
          flag = false
        }
      }
      if (!isEmpty(data.send_percent)) {
        if (isNaN(data.send_percent)) {
          toast.error('配送效率 请输入数字')
          flag = false
        }
      }
      if (!isEmpty(data.stores)) {
        if(!data.stores.toString().match(exp)){
          toast.error('线下门店数 请输入非负整数')
          flag = false
        }
      }
      if (!isEmpty(data.products_to)) {
        if(!data.products_to.toString().match(exp)){
          toast.error('应履约商品件数 请输入非负整数')
          flag = false
        }
      }
      if (!isEmpty(data.receives)) {
        if(!data.receives.toString().match(exp)){
          toast.error('直领 请输入非负整数')
          flag = false
        }
      }
      if (!isEmpty(data.freezes)) {
        if(!data.freezes.toString().match(exp)){
          toast.error('冻品 请输入非负整数')
          flag = false
        }
      }
      if (!isEmpty(data.others)) {
        if(!data.others.toString().match(exp)){
          toast.error('其他 请输入非负整数')
          flag = false
        }
      }
      if (!isEmpty(data.products_not)) {
        if(!data.products_not.toString().match(exp)){
          toast.error('未履约商品件数 请输入非负整数')
          flag = false
        }
      }
      if (data.agreement_product < 0) {
        toast.error('商品履约率 不能小于0')
        flag = false
      }
      if (data.agreement7 < 0) {
        toast.error('干线7H后履约率 不能小于0')
        flag = false
      }
      if (data.agreement11 < 0) {
        toast.error('11点履约率 不能小于0')
        flag = false
      }
      if (data.agreement16 < 0) {
        toast.error('16点履约率 不能小于0')
        flag = false
      }
      if (data.send_percent < 0) {
        toast.error('配送效率 不能小于0')
        flag = false
      }
      if (!isEmpty(data.sorted)) {
        if(!data.sorted.toString().match(exp)){
          toast.error('已分拣数 请输入非负整数')
          flag = false
        }
      }
      if(!isEmptyNew(data.receives) || !isEmptyNew(data.freezes) || !isEmptyNew(data.others)){
        if (addNumber(data.others,addNumber(data.receives,data.freezes)) != Number(data.products_to)) {
          toast.error('直领、冻品、其它三个字段总和为应履约商品数')
          flag = false
        }
      }
      if (!flag) return

      data.sort_percent = isEmpty(data.sorted) || data.products_to==0 ? 0 : ((data.sorted / data.products_to) * 100).toFixed(2)
      axios.post('api/gridhousedata/save', data).then(res => {
        if (res.data.code == 0) {
          toast.success("数据已保存")
          //刷新主表
          this.$emit('itemChange')
        } else {
          toast.error(res.data.data)
          refetchData()
        }
      })
    }

    const calculate1 = function (data) {
      if(isEmpty(data.others)){
        data.others = data.products_to - data.receives - data.freezes
      }else {
        data.freezes = data.products_to - data.receives - data.others
      }
      this.$forceUpdate()
    }

    const calculate2 = function (data) {
      if(isEmpty(data.receives)){
        data.receives = data.products_to - data.others - data.freezes
      }else {
        data.others = data.products_to - data.receives - data.freezes
      }
      this.$forceUpdate()
    }

    const calculate3 = function (data) {
      if(isEmpty(data.freezes)){
        data.freezes = data.products_to - data.others - data.receives
      }else {
        data.receives = data.products_to - data.others - data.freezes
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // UI
    } = gridhousedataUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      saveData,
      calculate1,
      calculate2,
      calculate3,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      isEmptyNew,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
