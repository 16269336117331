<template>
  <b-card
    class="gridhousedata-edit-wrapper"
  >
    <!-- form -->
    <b-form id="gridhousedataForm" class="edit-form mt-2">
      <b-row>
        <!--网格仓名称-->
        <b-col md="6">
          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label="网格仓名称"
              label-for="gh_id"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="gh_id"
                :options="gridhouse_options"
                v-model="gridhousedata.gh"
                class="select-size-sm"
                placeholder="请选择网格仓"
            />
          </b-form-group>
        </b-col>
        <!--日期-->
        <b-col md="6">
          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label="日期"
              label-for="year"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                id="year"
                v-model="gridhousedata.year"
                class="form-control"
            />
          </b-form-group>
        </b-col>
        <!--下单门店数-->
        <b-col md="6">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="下单门店数"
            label-for="stores"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="stores"
              size="sm"
              type="number"
              placeholder="请填写正整数"
              @blur="fixNumber('stores')"
              v-model="gridhousedata.stores"
            />
          </b-form-group>
        </b-col>
        <!--应履约商品件数-->
        <b-col md="6">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="应履约商品件数"
            label-for="products_to"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="products_to"
              size="sm"
              type="number"
              placeholder="请填写正整数"
              @blur="fixNumber('products_to')"
              v-model="gridhousedata.products_to"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import gridhousedataStore from './gridhousedataStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      gridhousedata: ref({}),
      gridhouse_options: [],
    }
  },
  methods: {
    getSaveInfo() {
      return this.gridhousedata
    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('gridhousedata')) store.registerModule('gridhousedata', gridhousedataStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('gridhousedata')) store.unregisterModule('gridhousedata')
    })

    const edit = function() {
      this.getGridHouseOptions()

      store.dispatch('gridhousedata/edit', {id: 0}).then(res => {
        this.gridhousedata = res.data.data
      })
    }

    const getGridHouseOptions = function () {
      axios.post('api/gridhouse/search').then(res => {
        let list = res.data.data.list
        this.gridhouse_options = []
        for (let i = 0; i < list.length; i++) {
          this.gridhouse_options.push({label: list[i].grid_name, value: list[i].gh_id})
        }
      })
    }

    const fixNumber = function (type) {
      if (!isEmpty(this.gridhousedata[type])) {
        this.gridhousedata[type] = Number(this.gridhousedata[type]).toFixed(0)
      } else {
        this.gridhousedata[type] = ''
      }
      this.$forceUpdate()
    }

    return {
      edit,
      getGridHouseOptions,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fixNumber,
    }
  },
  created() {
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
